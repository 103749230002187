/* Custom Fonts Import start */
@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Medium.eot');
    src: url('../fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Medium.woff2') format('woff2'),
        url('../fonts/Gilroy-Medium.woff') format('woff'),
        url('../fonts/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Regular.eot');
    src: url('../fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Regular.woff2') format('woff2'),
        url('../fonts/Gilroy-Regular.woff') format('woff'),
        url('../fonts/Gilroy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Heavy.eot');
    src: url('../fonts/Gilroy-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Heavy.woff2') format('woff2'),
        url('../fonts/Gilroy-Heavy.woff') format('woff'),
        url('../fonts/Gilroy-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Light.eot');
    src: url('../fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Light.woff2') format('woff2'),
        url('../fonts/Gilroy-Light.woff') format('woff'),
        url('../fonts/Gilroy-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-SemiBold.eot');
    src: url('../fonts/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-SemiBold.woff2') format('woff2'),
        url('../fonts/Gilroy-SemiBold.woff') format('woff'),
        url('../fonts/Gilroy-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Black.eot');
    src: url('../fonts/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Black.woff2') format('woff2'),
        url('../fonts/Gilroy-Black.woff') format('woff'),
        url('../fonts/Gilroy-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-ExtraBold.eot');
    src: url('../fonts/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-ExtraBold.woff2') format('woff2'),
        url('../fonts/Gilroy-ExtraBold.woff') format('woff'),
        url('../fonts/Gilroy-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-UltraLight.eot');
    src: url('../fonts/Gilroy-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-UltraLight.woff2') format('woff2'),
        url('../fonts/Gilroy-UltraLight.woff') format('woff'),
        url('../fonts/Gilroy-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Thin.eot');
    src: url('../fonts/Gilroy-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Thin.woff2') format('woff2'),
        url('../fonts/Gilroy-Thin.woff') format('woff'),
        url('../fonts/Gilroy-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Bold.eot');
    src: url('../fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Bold.woff2') format('woff2'),
        url('../fonts/Gilroy-Bold.woff') format('woff'),
        url('../fonts/Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

body {
    background-color: #FFF;
}

body * {
    padding: 0;
    margin: 0;
    font-family: 'Gilroy', sans-serif;
}

.mobile-wrap {
    max-width: 400px;
    width: 100%;
    margin-inline: auto;
}

img {
    width: auto;
    max-width: 100%;
}

a,
.btn {
    outline: 0 none;
    text-decoration: none;
}

.reorder-wrap {
    margin-bottom: 30px;
    margin-top: 100px;
}

.mobile-header {
    background-color: #fff;
    padding: 15px 20px;
    text-align: center;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    margin-bottom: 8px;
    position: fixed;
    width: 100%;
    z-index: 2;
    top: 0;
}

.mobile-header img {
    width: auto;
    max-width: 100%;
    max-height: 60px;
}

.main-content-top {
    padding: 24px 16px;
}

.main-content-top .title {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
}

.main-content-top p {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    margin: 0;
}

.lab-card {
    border-radius: 12px;
    border: 1px solid #F1F1F1;
    background: #FFF;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
    margin-top: 8px;
    padding: 16px;
}

.lab-logo {
    border-radius: 12px;
    border: 1px solid #D6D6D6;
    background: #FFF;
    padding: 4px;
    max-width: 56px;
    width: 100%;
}

.lab-logo img {
    width: auto;
    max-width: 100%;
}

.lab-name {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
}

.lab-dropdown .dropdown-toggle {
    border-radius: 200px;
    border: 1px solid rgba(93, 151, 50, 0.40);
    background: #FFF;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-transform: capitalize;
    padding: 3px 22px 3px 8px;
    position: relative;
}

.lab-dropdown .dropdown-toggle:hover,
.lab-dropdown .dropdown-toggle:active,
.lab-dropdown .dropdown-toggle:focus {
    outline: 0 none;
    box-shadow: unset;
    border: 1px solid rgba(93, 151, 50, 0.40);
    background: #FFF;
    color: #000;
}

.lab-dropdown .dropdown-toggle::after {
    display: none;
}

.lab-info {
    padding-left: 10px;
}

.lab-dropdown .chevron-icon {
    position: absolute;
    right: 6px;
    top: 7px;
    transition: all.4s;
}

.lab-dropdown .dropdown-toggle:not(.collapsed) .chevron-icon {
    transform: rotate(180deg);
}

.hr {
    background-color: #F0F0F0;
    width: 100%;
    height: 4px;
}

.option-card {
    padding: 16px;
}

.option-title {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;

}

.option-card .option-content {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
}

.common-tabs .nav-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    margin: 16px 0;
    border-bottom: medium none;
}

.common-tabs .nav-tabs .nav-item {
    width: calc(33.33% - 4px);
}

.common-tabs .nav-tabs .nav-link {
    border-radius: 12px;
    border: 1px solid #5D9732;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: #5D9732;
    padding: 12px 8px;
    display: block;
    position: relative;
}

.common-tabs .nav-tabs .nav-link.active {
    border: 1px solid #5D9732;
    background-color: #5D9732;
    color: #FFF;
}

.common-tabs .nav-tabs .nav-link small {
    display: block;
    font-size: 12px;
    font-weight: normal;
}

.form-content .form-label {
    color: #000;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    opacity: 0.6;
}

.form-content .form-control {
    border-radius: 8px;
    background-color: #F0EEF1;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding: 0.5rem 0.75rem;
}

.form-content .form-control:hover,
.form-content .form-control:active,
.form-content .form-control:focus {
    outline: 0 none;
    box-shadow: unset;
}

::placeholder {
    color: #ccc !important;
}

.common-tabs .nav-tabs .nav-link.disabled {
    opacity: 0.5;
}

.common-tabs .nav-tabs .nav-link.disabled::After {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-image: url("../images/lock-icon.svg");
    background-position: center center;
    background-repeat: no-repeat;
    left: 0;
    top: 0;
}

.form-content {
    margin-bottom: 15px;
}

.delivery-top {
    position: relative;
    padding-right: 30px;
    margin-top: 20px;
}

.delivery-title {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.title-content p {
    color: #000;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    opacity: 0.7;
}

.edit-btn-outline.btn {
    border-radius: 200px;
    border: 1px solid #5D9732;
    background-color: #FFF;
    color: #5D9732;
    font-family: Gilroy;
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
    padding: 4px 10px;
}

.delivery-top .btn {
    position: absolute;
    right: 0;
    top: 5px;
}

.grey-wrap {
    border-radius: 8px;
    background-color: #F8F8F8;
    padding: 10px 16px;
    margin-top: 8px;
}

.form-check-wrap {
    display: flex;
    align-items: center;
}

.form-check-wrap .form-check-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 13px);
    padding-left: 5px;
}

.form-check-wrap .form-check-label small {
    font-size: 16px;
    color: #000;
    font-weight: 500;
}

.payment-success-content img {
    max-width: 140px;
}

.order-btn.btn,
.order-btn.btn:hover,
.order-btn.btn:active,
.order-btn.btn:focus {
    background-color: #5D9732;
    /* border: 1px solid #5D9732; */
    width: 100%;
    border-radius: 8px;
    text-transform: capitalize;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    padding: 18px;
    outline: 0 none;
    box-shadow: unset;
}

.success-title {
    color: #000;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    margin: 0px 0 10px 0;
}

.error-message img {
    max-width: 32px;
    margin-right: 10px;
}

.error-message p {
    font-size: 12px;
}

.reorder-progress .nav .nav-item:last-child .nav-link {
    padding-bottom: 20px;
}

.payment-content-wrap {
    padding-left: 40px;
}

.payment-content-wrap .transaction-content {
    max-width: 310px;
    font-size: 12px;
}

.modal-header {
    background-color: #eefefd;
}

.modal-body {
    min-height: 300px;
}

.payment-success-modal .modal-header {
    border: medium none;
}

.payment-success-modal .btn-close,
.payment-success-modal .btn-close:hover,
.payment-success-modal .btn-close:active,
.payment-success-modal .btn-close:focus {
    padding: 0;
    outline: 0 none;
    box-shadow: unset;
    margin: 0;
    border: medium none;
}

.save-title {
    color: #000;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
}

.payment-success-content .form-check-radio {
    margin-top: 5px;
}

.offer-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
}

.insurance-icon {
    border-radius: 12px;
    overflow: hidden;
    height: 74px;
    width: 74px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.insurance-icon img {
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.insurace-text {
    width: calc(100% - 75px);
    padding-left: 15px;
}

.text-insure {
    color: #636363;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 6px;
}

.text-insure.eligible-text {
    font-size: 14px;
}

.text-insure:last-child {
    margin-bottom: 0;
}

.web-card {
    border-radius: 4px;
    border: 1px solid #E8E8E8;
    background: #FFF;
}

.web-card.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.mainSection.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.web-card .form-content .form-label {
    color: #003b4a;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    opacity: 1;
}

.delivery-content .form-content label {
    color: #003b4a;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    opacity: 1;
}

.delivery-content .form-content .StripeElement {
    border-radius: 8px;
    background-color: #F0EEF1;
    border: 1px solid #F0EEF1;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    padding: 0.8rem 0.75rem !important;
    height: 42px !important;
    line-height: 31px !important;
}

.delivery-content .form-content .StripeElement.is-invalid {
    border-color: #f00;
}

.ship-speed-title {
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    text-transform: uppercase;
    color: #003B4A;
    margin-bottom: 10px;
}

.web-card {
    margin-top: 16px;
}

.web-card.invalid-payment {
    border-radius: 4px;
    border: 1px solid #f00;
    background: #FFF;
}

.reorder-progress.web-card {
    padding: 24px 16px;
    margin-top: 0;
    position: fixed;
    width: 356px;
    top: 100px;
}

.reorder-progress .nav .nav-item .nav-link {
    color: #7E7E7E;
    font-size: 20px;
    padding: 0;
    background-color: #fff;
    position: relative;
    padding-left: 24px;
    padding-bottom: 40px;
}

.reorder-progress .nav .nav-item.active .nav-link,
.reorder-progress .nav .nav-item .nav-link.active {
    color: #555;
    font-weight: 600;
}

.reorder-progress .nav .nav-item .nav-link::before,
.reorder-progress .nav .nav-item .nav-link::after {
    content: "";
    position: absolute;
}

.reorder-progress .nav .nav-item .nav-link::before {
    width: 19px;
    height: 19px;
    flex-shrink: 0;
    border: 1px solid #E8E8E8;
    border-radius: 50%;
    left: -1px;
    top: 5px;
    background-color: #E8E8E8;
    z-index: 2;
}

.reorder-progress .nav .nav-item .nav-link::after {
    top: 6px;
    width: 2px;
    background-color: #E8E8E8;
    height: calc(100% + 10px);
    left: 7px;
    z-index: 1;
}

.reorder-progress .nav .nav-item.active .nav-link.active::before,
.reorder-progress .nav .nav-item.active .nav-link::before,
.reorder-progress .nav .nav-item .nav-link.active::before {
    border: 2px solid #fff;
    background-color: #555;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.22);
}

.reorder-progress .nav .nav-item.active .nav-link.active::after,
.reorder-progress .nav .nav-item.active .nav-link::after,
.reorder-progress .nav .nav-item .nav-link.active::after {
    background-color: #000000;
}

.reorder-progress .nav .nav-item:last-child .nav-link::after {
    display: none;
}

.wrap-input {
    position: relative;
}

.wrap-input .form-control,
.wrap-input .form-control:hover,
.wrap-input .form-control:active,
.wrap-input .form-control:focus {
    text-align: center;
    background-color: #fff;
    border-radius: 4px;
    padding-inline: 40px;
    outline: 0 none;
    box-shadow: unset;
    border: 1px solid #E8E8E8;
}

.wrap-input .count-btn,
.wrap-input .count-btn:hover,
.wrap-input .count-btn:active,
.wrap-input .count-btn:focus {
    border-radius: 0;
    background-color: #F7F7F7;
    min-height: 41px;
    min-width: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-size: 24px;
    border: medium none;
    line-height: 18px;
}

.wrap-input .count-btn.inc-count {
    left: 0;
}

.wrap-input .count-btn.dec-count {
    right: 0;
}

.common-tabs .nav-tabs .nav-link .save-flag {
    border-radius: 200px;
    border: 1px solid #FFF;
    background: #EE0B0B;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.22);
    display: inline-block;
    padding: 4px 8px;
    color: #FFF;
    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    text-transform: capitalize;
    position: absolute;
    top: -13px;
    left: 50%;
    transform: translateX(-50%);
    /* opacity: 0; */
}

.common-tabs .nav-tabs .nav-item:first-child .save-flag {
    background-color: #f1f1f1;
    color: #000;
    /* border-color: #f1f1f1; */
}

.common-tabs .nav-tabs .nav-item:nth-child(2) .save-flag {
    background-color: #f0ad4e;
    color: #000;
    /* border-color: #f0ad4e; */
}

.common-tabs .nav-tabs .nav-link:hover .save-flag {
    /* opacity: 1; */
}

.delivery-content-wrap .delivery-top .title-content {
    max-width: calc(100% - 50px);
    width: 100%;
}

.prescription {
    list-style: none;
    padding: 0;
    margin: 0;
}

.eye-options {
    width: 100%;
}

.prescription .each-info {
    width: 33.33%;
    margin-top: 12px;
}

.prescription .each-info label,
.prescription .each-info span {
    display: block;
}

.prescription .each-info label {
    color: #636363;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
}

.prescription .each-info span {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
}

.address-checkbox,
.form-check-wrap {
    position: relative;
    padding-left: 25px;
}

.address-checkbox .form-check-radio,
.form-check-wrap .form-check-radio {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}

.address-checkbox .check-custom,
.form-check-wrap .check-custom {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 2px;
    left: 0;
    border: 2px solid #555;
    border-radius: 50%;
    z-index: 0;
}

.address-checkbox .check-custom::after,
.form-check-wrap .check-custom::after {
    content: "";
    position: absolute;
    border: 2px solid #FFF;
    border-radius: 50%;
    height: 13px;
    width: 13px;
    background-color: #555;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.address-checkbox .form-check-radio+.check-custom::after,
.form-check-wrap .form-check-radio+.check-custom::after {
    opacity: 0;
}

.address-checkbox .form-check-radio:checked+.check-custom::after,
.form-check-wrap .form-check-radio:checked+.check-custom::after {
    /* opacity: 1; */
}

.address-checkbox .form-check-radio~.text-muted {
    display: none;
    /* max-width: 345px; */
}

.address-checkbox .form-check-radio:checked~.text-muted {
    display: block;
    font-size: 16px;

}

.verify-dob-modal .save-title {
    text-align: center;
    width: 100%;
}

.verify-dob-modal .form-group .form-control {
    border: 1px solid #E2E9F8;
    box-shadow: 0px 8px 24px 0px #00000019;
    border-radius: 12px;
    height: auto;
    line-height: 52px;
    text-align: center;
    max-width: 280px;
    margin: 20px auto 20px;
}

.verify-dob-modal .modal-body {
    min-height: unset;
}

.verify-dob-modal .transaction-content {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    margin: 0 auto 30px;
    max-width: 280px;
}

.verify-dob-modal .btn-primary {
    background-color: #8E88F4;
    box-shadow: 0px 6px 12px 0px #00000029;
    border: medium none;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    padding: 10px 18px;
    max-width: 170px;
    width: 100%;
    text-align: center;
    justify-content: center;
}

.verify-dob-modal .modal-header {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
}

.verify-dob-modal .modal-footer {
    background-color: #F5F5F5;
    border: medium none;
}

.dob-verify-controls label {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0px;
    opacity: 0.5;
}

.dob-verify-controls .form-control {
    border: 1px solid #7F7F7F;
    background-color: #EEFEFD;
}

.dob-verify-controls .form-control:hover,
.dob-verify-controls .form-control:active,
.dob-verify-controls .form-control:focus {
    outline: 0 none;
    box-shadow: unset;
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .container {
        max-width: 1180px;
    }
}

/* Web Responsive View CSS Start */
@media screen and (min-width: 768px) {
    .delivery-content .grey-wrap {
        margin-top: 16px;
    }
}

@media screen and (max-width: 1199.98px) {
    .reorder-progress.web-card {
        width: 290px;
    }
}

@media screen and (max-width: 991.98px) {
    .reorder-progress.web-card {
        width: 220px;
    }
}

@media screen and (max-width: 767.98px) {
    .reorder-progress.web-card {
        width: 100%;
    }

    .address-checkbox .check-custom::after,
    .form-check-wrap .check-custom::after {}

    .address-checkbox .form-check-radio~.text-muted {
        max-width: 345px;
    }

    .verify-dob-modal {
        overflow: hidden;
    }

    .reorder-progress .nav .nav-item .nav-link {
        padding-bottom: 0px;
    }

    .common-tabs .nav-tabs .nav-link .save-flag {
        white-space: nowrap;
    }

    .reorder-progress .nav .nav-item .nav-link::after {
        top: 10px;
        width: calc(100% + 5px);
        height: 2px;
        left: 7px;
    }

    .reorder-progress .nav .nav-item .nav-link::before {
        top: 3px;
    }

    .reorder-progress .nav.nav-pills {
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .nav.nav-pills li {
        flex-grow: 1;
    }

    .nav.nav-pills li:last-child {
        width: 16px;
        flex-grow: 0;
    }

    .nav.nav-pills.statusAll li {
        /* width: 25%; */
    }

    .reorder-progress .nav .nav-item .nav-link {
        font-size: 0pc;
    }

    .lab-card .lab-content {
        max-width: calc(100% - 60px);
        width: 100%;
    }

    .lab-card .lab-dropdown {
        max-width: 60px;
    }

    .lab-card {
        margin-top: 20px;
    }

    .reorder-progress.web-card {
        width: 100%;
        border: medium none;
        padding-bottom: 5px;
        padding-inline: 0;
    }

    .common-tabs .nav-tabs {
        margin-bottom: 0;
        justify-content: flex-start !important;
    }

    .common-tabs .nav-tabs li+li {
        margin-left: 4px;
    }

    .web-card {
        margin-top: 16px;
        border: medium none;
        position: relative;
        padding-bottom: 30px;
    }

    .reorder-progress.web-card,
    .payment-method-card.web-card {
        padding-bottom: 0;
    }

    .web-card::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: calc(100% + 30px);
        height: 4px;
        background-color: #F0F0F0;
        left: 50%;
        transform: translateX(-50%);
    }

    .reorder-progress.web-card::after,
    .payment-method-card.web-card::after {
        display: none;
    }

    .main-content-top,
    .option-card {
        padding: 24px 4px 0;
    }

    .option-card.insurance-card {
        padding-top: 0;
    }

    .delivery-left-content {
        margin-bottom: 20px;
    }

    .error-message {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .payment-success-modal {
        margin-top: 30px;
        /* min-height: 100vh; */
    }

    .payment-success-modal .payment-content-wrap {
        padding-left: 20px;
    }

    .reorder-wrap .reorder-progress.web-card {
        padding-top: 15px;
        top: 75px;
        position: fixed;
        max-width: 100%;
        margin: 0 auto;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        background-color: #fff;
        padding-bottom: 10px;
    }

    .reorder-wrap .reorder-progress.web-card .nav-pills {
        max-width: 90%;
        margin: 0 auto;
    }

    .mobile-header {
        padding: 8px 20px;
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 2;
    }

    .reorder-wrap {
        margin-top: 100px;
    }

    .modal-backdrop.show {
        opacity: 1;
    }
}

@media screen and (min-width: 768px) {
    .common-tabs .nav-tabs .nav-item {
        width: calc(33.33% - 16px);
    }

    .delivery-content {
        display: flex;
        justify-content: space-between;
    }

    .delivery-content .delivery-left-content {
        width: calc(50% - 16px);
    }

    .delivery-content .delivery-top {
        width: 100%;
    }

    .delivery-content .grey-wrap {
        width: calc(50% - 16px);
    }

    .form-btn .order-btn.btn {
        max-width: 240px;
        border-radius: 8px;
        background: #5D9732;
        margin-top: 24px;
    }

    .lab-content {
        max-width: calc(100% - 62px);
    }

    .lab-logo {
        min-width: 56px;
    }

    .nav-tabs {
        justify-content: flex-start !important;
    }

    .nav-tabs li+li {
        margin-left: 16px;
    }

    .common-tabs .nav-tabs .nav-link {
        padding: 15px 8px 12px;
    }
}

.overlay-dark {
    backdrop-filter: blur(2px) !important;
}

.overlay-dark::after {
    position: fixed;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: -1;
    min-height: 100vh;
}

/* Web Responsive View CSS End */

.grey-wrap .form-check-wrap .check-custom {
    top: 1px;
}

.fa-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear
}

.fa-pulse {
    -webkit-animation: fa-spin 1s infinite steps(8);
    animation: fa-spin 1s infinite steps(8)
}

@-webkit-keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

.check-custom b {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    left: 3px;
    top: 3px;
    position: absolute;
}